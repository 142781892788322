import { useQuery } from "@tanstack/react-query";
import { featuresApi } from "../../../api/apiTanStack";
import { BeachChairModel } from "../../../api-client/generated";
import dayjs from "dayjs";
import {
  IGrid,
  IGridGroupView,
  IGridView,
} from "../../../models/availability/IGrid";
import moment from "moment";

export const useBeachChairsAvailabilitiesCalendar = (
  id: number,
  start?: string,
  end?: string,
  row?: number,
  model?: BeachChairModel,
  free?: boolean,
  lock?: boolean
) =>
  useQuery({
    queryKey: [
      "beachChairsAvailabilitiesCalendar",
      id,
      start,
      end,
      row,
      model,
      free,
      lock,
    ],
    queryFn: async () => {
      const startOfYear = dayjs(start)
        .add(-1, "year")
        .startOf("year")
        .toISOString();
      const endOfYear = dayjs(end).add(2, "year").endOf("year").toISOString();
      const filters = new URLSearchParams(
        `filters[start]=${startOfYear}&filters[end]=${endOfYear}${
          free ? `&filters[free]=${free}` : ""
        }${lock ? `&filters[lock]=${lock}` : ""}${
          model ? `&filters[model]=${model}` : ""
        }${row ? `&filters[row]=${row}` : ""}`
      );
      return featuresApi
        .sectionsControllerGetSectionsBeachChairsBookings(
          {
            id,
          },
          { params: filters }
        )
        .then((response) => {
          const data = response.data as any as IGrid;
          const groups: IGridGroupView[] = data.groups.map((group) => {
            return {
              id: +group.id,
              title: group.title
                ? group.title
                : `${group.publicNumber}/${group.model}/${
                    group.lockId ? true : false
                  }${group.color ? "/" + group.color : ""}`,
            };
          });
          const grid: IGridView = {
            items: data.items,
            groups: groups,
          };
          let i = 0;
          return {
            groups: grid.groups.map((group) => {
              return {
                id: +group.id.toFixed(2),
                title: group.title,
              };
            }),
            items: grid.items.map((item) => {
              i += 1; // for uniqe id
              return {
                id: +`${i}00${item.id}`,
                group: item.group,
                title: item.title,
                start_time: moment(item.start_time),
                end_time: moment(item.end_time),
                type: item.type,
              };
            }),
          };
        });
    },
    enabled: !!start && !!end,
    retry: 0,
    cacheTime: Infinity,
    keepPreviousData: true,
  });
