import { useFieldArray, useForm } from "react-hook-form";
import { useCallback, useEffect, useMemo, useRef } from "react";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { useTranslation } from "react-i18next";
import { useBulkIdsStore } from "./useBulkIdsStore";
import dayjs from "dayjs";
import {
  usePatchBulkClearBeachChair,
  usePatchBulkUpdateBeachChair,
} from "./usePatchBulkUpdateBeachChair";

export function useBeachChairBulkForm() {
  const { ids } = useBulkIdsStore();
  const bulk = usePatchBulkUpdateBeachChair();
  const clear = usePatchBulkClearBeachChair();
  const { t } = useTranslation(["common"]);
  const validationSchema = useMemo(
    () =>
      yup.object().shape({
        Check: yup
          .object()
          .shape({
            buyingDate: yup.boolean(),
            sectionId: yup.boolean(),
            rowId: yup.boolean(),
            geoJson: yup.boolean(),
            status: yup.boolean(),
            model: yup.boolean(),
            afterHourBooking: yup.boolean(),
            onlineBooking: yup.boolean(),
            seasonBeachChair: yup.boolean(),
            internalNote: yup.boolean(),
            description: yup.boolean(),
            attributes: yup.boolean(),
            details: yup.boolean(),
            images: yup.boolean(),
            stopSaleDates: yup.boolean(),
          })
          .nullable(),
        buyingDate: yup.date().nullable(),
        sectionId: yup.number().nullable(),
        rowId: yup.number().nullable(),
        geoJson: yup.string().nullable(),
        status: yup.string().nullable(),
        model: yup.string().nullable(),
        afterHourBooking: yup.boolean().nullable(),
        onlineBooking: yup.boolean().nullable(),
        seasonBeachChair: yup.boolean().nullable(),
        internalNote: yup.string().nullable(),
        description: yup.string().nullable(),
        attributes: yup
          .object()
          .shape({
            color: yup.string().nullable(),
          })
          .nullable(),
        details: yup.object().shape({
          extraInformation: yup
            .object()
            .shape({
              de: yup.string().nullable(),
              en: yup.string().nullable(),
            })
            .nullable(),
        }),
        Clear: yup.object().shape({
          description: yup.boolean().nullable(),
          internalNote: yup.boolean().nullable(),
          extraInformation: yup.boolean().nullable(),
          images: yup.boolean().nullable(),
          stopSalesDates: yup.boolean().nullable(),
        }),
      }),
    [t]
  );

  const {
    watch,
    control,
    setValue,
    getValues,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(validationSchema),
  });

  useEffect(() => {
    setValue("buyingDate", dayjs());
  }, []);
  const {
    append: imagesAppend,
    remove: imagesRemove,
    fields: imagesFields,
  } = useFieldArray({ name: "images", control });

  const { append, remove, update, fields } = useFieldArray({
    name: "stopSaleDates",
    control,
  });

  const onSubmit = useCallback(
    (formValues) => {
      const { Check, ...rest } = formValues;
      rest.images =
        rest.images?.map((items: { value: string }) => items.value) || [];
      const property = Object.keys(Check).filter((prop) => Check[prop]);
      const result = property.map((prop) => {
        return {
          [prop]: rest[prop],
        };
      });
      const update = Object.assign({}, ...result);
      if (result.length > 0) {
        bulk.mutate({ ids: ids, update: update });
      }
      const { Clear } = formValues;
      clear.mutate({ ids: ids, clear: Clear });
    },
    [ids]
  );

  return {
    watch,
    control,
    errors,
    setValue,
    getValues,
    append,
    remove,
    fields,
    update,
    onSubmit: handleSubmit(onSubmit),
    imagesAppend,
    imagesRemove,
    imagesFields,
    count: ids.length ?? 0,
  };
}
