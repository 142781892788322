export const customStyle = {
  mainColor: "#233F91",
  inBooking: "#07BC0C",
  secondaryColor: "#ED6B17",
  contentColor: "#FAFAFA",
  textColor: "#FFFFFF",
  whiteColor: "#FFFFFF",
  lightGray: "#EDEDED",
  gray: "#808080",
  scrollColor: "#233F91",
  grayColor: "rgba(0, 0, 0, 0.54)",
  menuWidth: "230px",
  menuUserInfo: "180px",
  minMenuWidth: "70px",
  headerFooterHeight: "70px",
  withoutIconPadding: 9,
};
